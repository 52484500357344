import { useState } from 'react';

/**
 * custom hooks to mutate data
 * @param {string} HOSTNAME
 * @param {string} TABLE_NAME
 */
function useMutation(HOSTNAME, TABLE_NAME, ACTION) {
  const [loading, setLoading] = useState(false);

  const onMutateData = async (options) => {
    try {
      setLoading(true);
      const config = `tableName=${TABLE_NAME}&action=${ACTION}`;
      const rawResult = await fetch(`${HOSTNAME}?${config}&${options}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      const response = await rawResult.json();
      setLoading(false);
      return response;
    } catch (e) {
      console.error(e);
      setLoading(false);
      return { success: false, error: e };
    }
  };

  return [onMutateData, loading];
}

export default useMutation;
