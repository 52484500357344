import { useState } from 'react';

function useFormFields(initialValues = {}) {
  const [formFields, setFormFields] = useState(initialValues);

  const createChangeHandler = (key) => (e) => {
    e.preventDefault();
    const value = e.target.value;
    setFormFields((prev) => ({ ...prev, [key]: value }));
  };

  const resetForm = () => {
    setFormFields(initialValues);
  };

  return { formFields, createChangeHandler, onResetForm: resetForm };
}

export default useFormFields;
