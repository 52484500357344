import { css } from '@emotion/react';

export const styles = css`
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: var(--chakra-shadows-2xl);

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 70%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(245, 246, 232, 0.8) 85%
    );
  }
`;
