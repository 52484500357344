import { css } from '@emotion/react';
import getChackra from '@invitato/helpers/dist/getChackra';

import B1 from './assets/wave.svg';
import B2 from './assets/wave2.svg';
import B2white from './assets/wave2-invert.svg';
import V2 from './assets/wave-oval.svg';
import V2White from './assets/wave-oval-white.svg';
import V2Transparent from './assets/wave-oval-transparent.svg';

const getBg = (isWhite) => {
  if (isWhite) return B2white;

  return B2;
};

export const styWave = (inverse, v2, isWhiteColor) => css`
  background-image: url(${v2 ? getBg(isWhiteColor) : B1});
  background-size: cover;
  min-height: 3rem;
  background-color: ${getChackra('bgPrimary')};
  ${inverse && 'transform: rotate(180deg)'}
`;

export const styWaveV2 = ({ inverse, isWhiteColor, bgColor, isTransparent }) => css`
  background-image: url(${isWhiteColor ? V2White : V2});
  ${isWhiteColor && `background-image: url(${V2White});`}
  ${!isWhiteColor && `background-image: url(${V2});`}
  ${isTransparent && `background-image: url(${V2Transparent});`}
  background-size: cover;
  min-height: 4.5rem;
  background-color: ${bgColor};
  ${inverse && 'transform: rotate(180deg)'}
`;
