export const txtDay = {
  id: 'Hari',
  en: 'days',
};

export const txtHour = {
  id: 'Jam',
  en: 'hours',
};

export const txtMinutes = {
  id: 'Menit',
  en: 'mins',
};

export const txtSeconds = {
  id: 'Detik',
  en: 'secs',
};
