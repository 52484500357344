import React from 'react';
import { string } from 'prop-types';
import { Box } from '@chakra-ui/react';

function IconWeddingRing({ color, height, width, ...rest }) {
  return (
    <Box height={height} width={width} {...rest}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 1280">
        <g fill={color}>
          <path d="M826.5 241.7c-.6.5-3.3 3.9-6 7.8-2.7 3.8-13.5 15.5-24 26-10.4 10.4-19.9 20.7-21 22.7-1.8 3.5-1.8 3.8-.2 5.6 1.1 1.2 4.4 2.4 8.8 3.1 3.9.7 6.8 1.5 6.6 1.7-.3.3-3.5-.1-7.2-.7-6.3-1.2-6.8-1.2-8.1.6-.8 1.1-1.1 2.8-.8 3.9 2.5 8.1 65 132.9 77.5 155 4 6.9 6.1 8.6 8.7 7 3.8-2.5 3.9-12.6.2-85.4-.5-9.6-.9-27.2-.9-39.1-.1-16.1-.4-21.9-1.3-22.7-.7-.5-8.2-2.7-16.6-4.7-16.4-4-20.4-5.8-5.6-2.5 4.9 1.1 10.5 2 12.5 2 6.8 0 6.9-.8 1-24.2-5.8-23.4-12.9-46.2-16.4-52.3-2.2-3.9-5-5.4-7.2-3.8zm-31.8 67.9c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm4 1c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm4 1c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm4 1c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm4 1c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm15 4c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5z" />
          <path d="M837.7 243.1c-1.4 1.9-1.2 3.1 1.9 12.2 4.8 14.1 11.9 39.3 14.4 50.7 2.6 12.1 3.4 14 6.1 14 1.2 0 4.4-1.6 7-3.5 2.7-1.9 5.1-3.5 5.4-3.5.3 0-1.1 2.2-3 4.9-1.9 2.6-3.5 6-3.5 7.5s-.6 2.6-1.4 2.6c-3.8 0-3.7 1.5 1.4 58.1 2.8 30.5 5.4 56.5 5.8 57.9.5 1.7 1.6 2.6 3.3 2.8 2.1.2 3.8-.9 8.2-5.5 11.9-12.5 26.3-31.7 40.4-53.8 11.7-18.5 21.3-35 21.3-36.8 0-.8-.7-2-1.5-2.7-1.4-1.1-1.3-1.4.7-2.4 1.3-.6 2.4-1.9 2.6-2.9.3-1.7-9.7-37.8-16.8-60.7-4.2-13.5-6.1-17-8.9-17-1.4 0-1.7-.6-1.4-2.3.3-1.4-.2-2.9-1.1-3.6-1.5-1.2-73.9-18-77.8-18.1-.9 0-2.3.9-3.1 2.1zm64 93.5c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm13.8 3.3c6.1 1.6 10.4 3 9.7 3-1.5.1-21.5-4.8-22.1-5.5-.8-.8 1.5-.3 12.4 2.5zm14.2 3.7c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm4 1c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zm5 1c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5zM931.2 263.1c-1.5.6-2.2 1.7-2.2 3.4 0 2.6 20.3 70.2 22.4 74.5 2 4.2 4.8 3.7 9.1-1.7 2.2-2.6 11.6-12.5 21-21.8 23.3-23.1 31.2-34.8 25.5-37.5-2.1-1-58.1-14.8-66.6-16.4-4-.7-7.9-1-9.2-.5zM990.9 309.5c-29 29.4-34.9 37.1-32.1 41.5 1 1.6.6 1.8-4 1.6l-5.1-.1-6.5 11.5c-3.5 6.3-14.4 24.8-24.2 41-19.4 32.3-27.6 47.3-34 62.3-4 9.3-4.1 9.9-2.6 12.3.9 1.3 2.3 2.4 3 2.4.8 0 8.1-5 16.2-11.1 14.2-10.6 36.6-26.4 84.4-59.3 32.7-22.6 48-34.2 48-36.6 0-2.3-3.1-4.5-7.8-5.5-1.8-.4-3.1-.9-2.9-1.2.3-.3 3.1.1 6.2.7 5.1 1 5.9 1 7.6-.6 1.2-1.1 1.9-2.7 1.6-3.9-.2-1.1-4.7-16-10-33-5.3-17.1-10.3-33.5-11.2-36.6-2.7-9.6-2.9-9.5-26.6 14.6zm-4.7 48.4c14.3 3.7 21.6 5.9 19.3 6-.5 0-9.8-2.2-20.5-4.9s-18.3-4.9-16.8-4.9c1.5-.1 9.6 1.7 18 3.8zm35.5 9.7c-.3.3-1.2.4-1.9.1-.8-.3-.5-.6.6-.6 1.1-.1 1.7.2 1.3.5z" />
          <path d="M486.5 404.7c-67.4 5.3-120.3 25.2-168.7 63.4-20.3 16-48.4 46-65.5 69.9-50.7 70.8-68.6 161.6-50.2 254 12.2 60.8 41.9 114.8 87.5 159 50.5 48.8 109.4 76.6 181.4 85.7 19 2.3 59 2.3 76 0 52-7.3 101.3-27.6 158.3-65.3 20.2-13.4 25.8-18.2 28.8-24.7 4.1-9.1 2.7-11.2-11-17.2-6.1-2.7-11-5-10.8-5.1.2-.2 3.8 1 8 2.7 18.7 7.2 46.4 13.6 69.1 15.8 14.1 1.4 44.1 1.4 58.2 0 62.3-6.1 117.7-32.2 161.4-75.9 42.8-42.8 67.2-93.8 74.6-156 1.9-16.4 1.9-50.7 0-67.1-5.9-48.7-24.1-92.3-54.4-129.9-14.9-18.5-42.4-43.7-62.4-57.4l-5.7-3.9-6.8 2.2c-8.4 2.7-17.8 8.2-28 16.3-12 9.5-18.3 15.8-18.3 18.3 0 1.6 2.9 4.1 12.7 10.5 60.8 40.1 93.9 88.4 100.8 147 9.8 82.4-23.6 157.2-89.5 200.2-68.7 45-152.1 47.7-218.9 7.2-24.3-14.8-46.9-36.2-62.1-58.8-34.2-51-44.9-129.9-25.4-188.6 6.7-20.3 20.4-45.3 33.1-60.5 7.4-8.9 7.8-9.9 5.1-12.8-4.8-5.2-42.7-27.7-46.6-27.7-4.1 0-25.5 32.7-38.6 59.1-13.9 27.9-21.7 54.1-24.7 82.7-1.6 15.2-.6 57.1 1.6 71.2 7.6 48.7 24.1 87 53.6 125 15.7 20.2 40.9 43.7 60.8 56.7 8.8 5.7 10.5 7.3 7.7 7.3-.7 0-7.8 3.9-15.6 8.6-41.3 24.8-79.7 41.8-107.1 47.4-11 2.3-37.8 4.1-49 3.5-20.8-1.3-25.2-1.7-34.4-3.1-49.8-7.7-94.2-30.1-131.9-66.4-22-21.2-35.5-39.5-48.1-65.2-11.3-23.1-18.7-47.7-22.7-75.8-2-14.7-1.7-57.6.5-72 4.1-26.6 10.2-46 21.7-69.5 12.1-25 27.2-46.3 48-67.9 36.7-38 79.3-60.4 131-68.7 18-2.9 56.6-3.7 75.2-1.5 46.9 5.4 87.4 22.3 123.7 51.6 53.4 43.1 82.8 99.5 88.1 169.5 2.8 37-6.4 83.3-27.6 139.2-5.4 14.5-5.9 17.5-2.6 18.8 31.3 12.2 40.2 15.1 51.6 16.5 6.6.8 8.4.7 10.2-.5 2.6-1.7 12.7-21.9 20.2-40.5 8.6-21.4 15.2-45.2 18.9-69 1.5-9.4 1.8-17.4 1.8-44-.1-28.6-.4-34.2-2.3-47-10.7-69-38.3-124.4-86.6-173.8-54.6-56-120.9-86.4-202.9-93.1-11.6-1-41.3-1.2-51.2-.4zM690 913.6c0 .2-.9 0-2-.6s-2-1.3-2-1.6c0-.2.9 0 2 .6s2 1.3 2 1.6z" />
          <path d="M792 411.7c-32.4 4.1-70.2 15.3-97 28.8-7 3.5-8.6 5.4-7.1 8.4 1.2 2.1 15.7 14.7 32.4 28 18.8 14.9 16.3 13.9 24.9 9.8 20.8-9.8 45-14.7 73.4-14.7 8 0 10.6-.3 11.8-1.6.9-.8 1.6-2.1 1.6-2.7-.1-4-25.8-56.4-28-57-.8-.2-6.2.3-12 1z" />
        </g>
      </svg>
    </Box>
  );
}

IconWeddingRing.propTypes = {
  color: string,
  height: string,
  width: string,
};

IconWeddingRing.defaultProps = {
  color: 'black',
  height: 'auto',
  width: 'auto',
};

export default IconWeddingRing;
