import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return parner
 * @return {string}
 */
function usePartner() {
  const location = useLocation();
  const partner = getQueryValue(location, 'partner');

  return Number(partner || 0);
}

export default usePartner;
